import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Img from 'gatsby-image'

const ImageCarousel = ({ carouselImages }) => {


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    const returnImages = () => (
        carouselImages.map(image => (
            <div
                key={image.childImageSharp.fluid.src}
                className="carousel-image is-relative"
            >
                <Img
                    fluid={image.childImageSharp.fluid}
                    className="cover"
                    alt={image.childImageSharp.originalName}
                />
            </div>
        ))
    )
    return (
        <Carousel
            className="carousel"
            responsive={responsive}
            infinite={true}
        >
            {returnImages()}
        </Carousel>
    )
}

export default ImageCarousel;
