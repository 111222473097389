import React from "react"
import { graphql, Link } from "gatsby"
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Carousel from "../components/carousel"


const IndexPage = ({ data }) => {
  const carouselImages = [
    data.carousel1,
    data.carousel2,
    data.carousel3,
    data.carousel4,
    data.carousel5
  ]
  return (
    < Layout >
      <SEO title="Home" />
      <section className="section is-paddingless">
        <Carousel carouselImages={carouselImages} />
      </section>
      <section className="section page-title has-background-light">
        <div className="container">
          <h1 className="title is-5 is-uppercase">{data.page.siteMetadata.title}</h1>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content is-medium">
            <h1 className="title is-5">
              {data.page.siteMetadata.description}
            </h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
              Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
          </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container is-relative">
          <Img className="cover" fluid={data.pageImage.childImageSharp.fluid}></Img>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="content is-medium">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sapien enim, fringilla quis placerat nec, ultricies non massa.
              Proin vel velit ullamcorper, dapibus eros eu, laoreet erat. Pellentesque cursus eros augue, sed sagittis nibh suscipit ac. Quisque in erat ipsum.
              Integer tincidunt enim non nibh bibendum porta. Nullam velit justo, placerat eu dui a, mollis aliquet mi. Etiam vitae tincidunt nisl.
              Curabitur eleifend dictum lorem. Donec posuere nulla eu lectus cursus vulputate. Suspendisse potenti. Morbi aliquam facilisis mattis.
          </p>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="cta-buttons buttons flex-div">
            <Link className="button is-primary is-medium" role="button" to="/tietoa-kohteesta">Tietoa kohteesta</Link>
            <Link className="button is-primary is-medium" role="button" to="/yhteystiedot">Yhteystiedot</Link>
          </div>
        </div>
      </section>
    </Layout >
  )
}
export const query = graphql`
query IndexPageQuery {
  page: site {
    siteMetadata {
      title
      description
    }
  }
  pageImage: file(relativePath: {eq: "07.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 1344, quality: 80) {
        ...GatsbyImageSharpFluid
     }
    }
  }
  carousel1: file(relativePath: {eq: "02.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 900, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
     }
    }
  }
  carousel2: file(relativePath: {eq: "03.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 900, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
     }
    }
  }
  carousel3: file(relativePath: {eq: "04.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 900, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
     }
    }
  }
  carousel4: file(relativePath: {eq: "05.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 900, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
     }
    }
  }
  carousel5: file(relativePath: {eq: "06.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 900, cropFocus: CENTER) {
        ...GatsbyImageSharpFluid
     }
    }
  }
}
`
export default IndexPage;
